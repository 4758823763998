<template>
  <div>
    <a-table rowKey="id" size="small" :columns="columns" :dataSource="dataItems" :pagination="false" :scroll="{ y: 240 }">
      <template slot="action" slot-scope="value, item">
        <a-button size="small" type="danger" @click="removeItem(item)">移除</a-button>
      </template>
    </a-table>

    <a-button type="primary" block style="margin-top: 12px" @click="selectModalVisible = true">添加点检项目</a-button>
    <InspectionSelectModal v-model="selectModalVisible" @select="onSelect" />
  </div>
</template>

<script>
export default {
  components: {
    InspectionSelectModal: () => import("@/components/InspectionSelectModal"),
  },
  props: ["dataItems"],
  model: { prop: "dataItems", event: "change" },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "编号",
          dataIndex: "number",
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "项目内容",
          dataIndex: "content",
        },
        {
          title: "标准",
          dataIndex: "standard",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 60,
          scopedSlots: { customRender: "action" },
        },
      ],

      selectModalVisible: false,
    };
  },
  methods: {
    removeItem(item) {
      const newDataItem = this.dataItems.filter((dataItem) => dataItem.id !== item.id);
      this.$emit("change", newDataItem);
    },
    onSelect(items) {
      const newDataItem = [...this.dataItems];
      items.forEach((item) => {
        if (newDataItem.some((dataItem) => dataItem.id === item.id)) {
          this.$message.warning(`点检项目[${item.name}] 已存在`);
        } else {
          newDataItem.push(item);
        }
      });

      this.$emit("change", newDataItem);
    },
  },
};
</script>

<style scoped></style>
